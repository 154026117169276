import Tooltip from '@atlaskit/tooltip';
import React, { useState } from 'react';
import { Pagination as BootstrapPagination } from 'react-bootstrap';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useQueryClient } from 'react-query';
import PaginationItem from './PaginationItem';
import {
  BootstrapPaginationDots,
  BootstrapPaginationFirst,
  BootstrapPaginationLast,
  BootstrapPaginationButton,
} from './styles';

interface PaginationProps {
  rowsCount: number;
  limit?: number;
  currentPage?: number;
  updatePage?: () => void;
  tela: number;
  onPageChange: (page: number) => void;
  hasButtonUpdate?: boolean;
}
const siblingsCount = 1;

function generatePagesArray(from: number, to: number) {
  return [...new Array(to - from)]
    .map((_, index) => {
      return from + index + 1;
    })
    .filter((page) => page > 0);
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const {
    rowsCount,
    limit = 10,
    currentPage = 1,
    tela,
    onPageChange,
    updatePage,
    hasButtonUpdate = true,
  } = props;

  const [loading, setLoading] = useState(false);

  let lastPage = Math.ceil(rowsCount / limit);
  if (lastPage === 0) {
    lastPage = 1;
  }
  const previousPages =
    currentPage > 1
      ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : [];

  const nextPages =
    currentPage < lastPage
      ? generatePagesArray(
          currentPage,
          Math.min(currentPage + siblingsCount, lastPage),
        )
      : [];

  async function refetchData() {
    setLoading(true);
    if (updatePage) {
      updatePage();
    }
    setLoading(false);
  }
  return (
    <BootstrapPagination>
      {hasButtonUpdate && (
        <Tooltip
          position="left"
          content={`Atualizar dados da página ${currentPage}`}
        >
          <BootstrapPaginationButton
            onClick={() => {
              refetchData();
            }}
          >
            {loading ? (
              <CircularProgress size={15} style={{ color: '#ffffff' }} />
            ) : (
              'Atualizar'
            )}
          </BootstrapPaginationButton>
        </Tooltip>
      )}

      <Tooltip position="top" content="Voltar">
        <BootstrapPaginationFirst
          disabled={currentPage - 1 < 1}
          onClick={() =>
            onPageChange(currentPage - 1 <= 0 ? 1 : currentPage - 1)
          }
        >
          <BsChevronLeft />
        </BootstrapPaginationFirst>
      </Tooltip>
      {currentPage > 1 + siblingsCount && (
        <>
          <Tooltip position="top" content={`Página ${1}`}>
            <PaginationItem onPageChange={() => onPageChange(1)} page={1} />
          </Tooltip>
          {currentPage > 2 + siblingsCount && (
            <BootstrapPaginationDots
              style={{ marginTop: '10px', cursor: 'default' }}
            >
              ...
            </BootstrapPaginationDots>
          )}
        </>
      )}

      {previousPages.length > 0 &&
        previousPages.map((page) => {
          return (
            <Tooltip position="top" key={page} content={`Página ${page}`}>
              <PaginationItem
                onPageChange={() => onPageChange(page)}
                page={page}
                key={page}
              />
            </Tooltip>
          );
        })}
      <Tooltip position="top" content={`Página ${currentPage}`}>
        <PaginationItem
          onPageChange={() => onPageChange(currentPage)}
          page={currentPage}
          isCurrent
        />
      </Tooltip>

      {nextPages.length > 0 &&
        nextPages.map((page) => {
          return (
            <Tooltip position="top" key={page} content={`Página ${page}`}>
              <PaginationItem
                onPageChange={() => onPageChange(page)}
                page={page}
                key={page}
              />
            </Tooltip>
          );
        })}

      {currentPage + siblingsCount < lastPage && (
        <>
          {currentPage + 1 + siblingsCount < lastPage && (
            <BootstrapPaginationDots
              style={{ marginTop: '10px', cursor: 'default' }}
            >
              ...
            </BootstrapPaginationDots>
          )}
          <Tooltip position="top" content={`Página ${lastPage}`}>
            <PaginationItem
              onPageChange={() => onPageChange(lastPage)}
              page={lastPage}
            />
          </Tooltip>
        </>
      )}
      <Tooltip position="top" content="Avançar">
        <BootstrapPaginationLast
          disabled={currentPage + 1 > lastPage}
          onClick={() =>
            onPageChange(
              currentPage + 1 >= lastPage ? lastPage : currentPage + 1,
            )
          }
        >
          <BsChevronRight />
        </BootstrapPaginationLast>
      </Tooltip>
    </BootstrapPagination>
  );
};

export default Pagination;
