import * as yup from 'yup';

export const schemaFormTela = yup
  .object({
    cod_modulo: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
  })
  .required();
