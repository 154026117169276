/* eslint-disable prettier/prettier */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdArrowUpward,
  MdArrowDownward,
} from 'react-icons/md';
import {
  Container,
  ContainerItem,
  Controller,
  List,
  Button,
  ListaItem,
} from './styles';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { CancelButton, ConfirmButton } from '~/components/Buttons';
import { ModulosContainer } from './components/Containers/ModulosContainer';
import { useTelas } from './data';
import { useTela } from './OrderTelasContext';
import { errorHandler } from '~/utils/ErrorHandler';
import api from '~/services/api';
import { toast } from 'react-toastify';

interface OptionsProps {
  num_ordem_menu: number;
  cod_tela: number;
  des_tela: string;
}

interface OrderTelasProps {
  isShow: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SeparateOptionsProps {
  allOptions: OptionsProps[];
  cod_tela: number | undefined;
}

interface SortOptionsProps {
  allOptions: OptionsProps[];
  setOptions: (item: OptionsProps[]) => void;
}
interface UniqueOptionsProps {
  allOptions: OptionsProps[];
}

export const OrderTelasContent: React.FC<OrderTelasProps> = ({
  isShow,
  setIsShow,
}) => {
  const [selected, setSeleted] = useState<number>();

  const { formTela } = useTela();

  const { watch } = formTela;

  const watchCodModulo = watch('cod_modulo');

  const { telas } = useTelas();

  useEffect(() => {
    setItems(telas);
  }, [telas]);

  const [items, setItems] = useState<OptionsProps[]>([]);

  function handleCampo(cod_tela: number): void {
    setSeleted(cod_tela);
  }

  function moveItemSelectedToTopOrBase(action: string) {
    const { option, options } = separateOptions({
      allOptions: items,
      cod_tela: selected,
    });
    if (option) {
      if (action === 'top') {
        options.unshift(option);
      } else {
        options.splice(options.length, 0, option);
      }
      sortOptions({
        allOptions: options,
        setOptions: setItems,
      });
    }
  }

  function moveItemSelectedToUpOrDown(action: string) {
    const { option, options } = separateOptions({
      allOptions: items,
      cod_tela: selected,
    });
    if (option) {
      if (action === 'up') {
        const num_ordem = option.num_ordem_menu - 1;
        options.splice(num_ordem < 0 ? 0 : num_ordem, 0, option);
      } else {
        options.splice(option.num_ordem_menu + 1, 0, option);
      }
      sortOptions({
        allOptions: options,
        setOptions: setItems,
      });
    }
  }

  function separateOptions({ allOptions, cod_tela }: SeparateOptionsProps) {
    const options = allOptions.filter((item) => item.cod_tela !== cod_tela);
    const option = allOptions.find((item) => item.cod_tela === cod_tela);

    return {
      options,
      option,
    };
  }

  function sortOptions({ allOptions, setOptions }: SortOptionsProps) {
    const dataOrdenado = allOptions.map((item, index) => ({
      ...item,
      num_ordem_menu: index,
      cod_tela: item.cod_tela,
      des_tela: item.des_tela,
    }));
    const dataUnique = uniqueOptions({ allOptions: dataOrdenado });
    setOptions(dataUnique);
  }

  function uniqueOptions({ allOptions }: UniqueOptionsProps) {
    const dataUnique = [];
    const map = new Map();
    // eslint-disable-next-line no-restricted-syntax
    for (const option of allOptions) {
      if (!map.has(option.cod_tela)) {
        map.set(option.cod_tela, true); // set any value to Map
        dataUnique.push({
          ...option,
          num_ordem_menu: option.num_ordem_menu,
          cod_tela: option.cod_tela,
          des_tela: option.des_tela,
        });
      }
    }
    return dataUnique;
  }

  function handleOnDragEnd(result: any): void {
    const { destination, source } = result;

    if (destination.droppableId === source.droppableId) {
      const { option, options } = separateOptions({
        allOptions: items,
        cod_tela: selected,
      });
      if (option) {
        options.splice(destination.index, 0, option);
      }
      sortOptions({
        allOptions: options,
        setOptions: setItems,
      });
    }
  }

  const handleCloseModal = () => {
    setIsShow(false);
  };

  const onConfirmClick = async () => {
    const res = await api.put(`/tela/ordenar-campos`, {
      telas: items,
    });
    const { message } = res.data;
    handleCloseModal();
    return toast.success(message);
  };

  const onCancelClick = () => {
    setIsShow(false);
  };

  return (
    <>
      <Modal show={isShow} size="lg">
        <Modal.Header>
          <Modal.Title>Ordenação de telas</Modal.Title>
          <button
            onClick={handleCloseModal}
            type="button"
            className="btn-close"
          >
            {}
          </button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={12}>
                <ModulosContainer />
              </Col>
              <Col xs={12} className="mt-2">
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <ContainerItem>
                    <Form.Label className="title">
                      Telas ativas do módulo
                    </Form.Label>
                    <List>
                      <Droppable droppableId="selecionados" key="2">
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              background: snapshot.isDraggingOver
                                ? '#dfe1e6'
                                : '',
                              height: '100%',
                            }}
                          >
                            {items.map((item, index) => (
                              <Draggable
                                draggableId={`${item.cod_tela}`}
                                key={item.cod_tela}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    id={`${item.cod_tela}`}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <ListaItem
                                      onPointerDownCapture={() => {
                                        handleCampo(item.cod_tela);
                                      }}
                                      className={
                                        selected === item.cod_tela
                                          ? 'selected'
                                          : 'unselected'
                                      }
                                      style={{
                                        padding: '0px 16px',
                                      }}
                                    >
                                      {item.des_tela}
                                    </ListaItem>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </Droppable>
                    </List>
                    <Controller>
                      <Button
                        type="button"
                        onClick={() => moveItemSelectedToTopOrBase('top')}
                      >
                        <MdArrowUpward />
                      </Button>
                      <Button
                        type="button"
                        onClick={() => moveItemSelectedToUpOrDown('up')}
                      >
                        <MdKeyboardArrowUp />
                      </Button>
                      <Button
                        type="button"
                        onClick={() => moveItemSelectedToUpOrDown('down')}
                      >
                        <MdKeyboardArrowDown />
                      </Button>
                      <Button
                        type="button"
                        onClick={() => moveItemSelectedToTopOrBase('base')}
                      >
                        <MdArrowDownward />
                      </Button>
                    </Controller>
                  </ContainerItem>
                </DragDropContext>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12}>
                <div className="d-flex align-items-center justify-content-end">
                  <CancelButton
                    onClick={() => {
                      onCancelClick();
                    }}
                  >
                    Cancelar
                  </CancelButton>
                  <ConfirmButton
                    disabled={!watchCodModulo?.value}
                    onClick={() => {
                      onConfirmClick();
                    }}
                  >
                    Confirmar
                  </ConfirmButton>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};
