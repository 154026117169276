/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { Pagination } from 'react-bootstrap';

interface BootstrapPaginationProps {
  active?: boolean;
  notInterative?: boolean;
}

interface BootstrapPaginationArrowsProps {
  disabled?: boolean;
}

export const BootstrapPagination = styled(Pagination.Item) <BootstrapPaginationProps>`
  margin-top: 10px !important;

  a {
    border-radius: 0px !important;
    ${(props) => props.active && `
      background: ${props.theme.colors.nav} !important;
      color: #fff !important;
    `}
  }
  &.active {
    .page-link {
      border-color: transparent;
    }
    span {
      background: ${(props) => props.theme.colors.nav} !important;
      color: #fff !important;
    }
  }
`;

export const BootstrapPaginationDots = styled(Pagination.Item) <BootstrapPaginationProps>`
  margin-top: 10px !important;
  a {
    border-radius: 0px !important;
    cursor: default !important;
    &:focus {
      color: #0d6efd;
      background-color: #fff;
      outline: 0;
      box-shadow: none;
    }
    &:hover {
      color: #0d6efd;
      background-color: #fff;
    }
    &:focus:not(.focus-visible) {
      outline: none !important;
    }
    &:focus-visible {
      outline: 1px solid ${({ theme: { colors } }) =>
      colors.secondary} !important;
    }
  }
`;

export const BootstrapPaginationFirst = styled(Pagination.First)<BootstrapPaginationArrowsProps>`
  margin-top: 10px !important;
  margin-right: 10px !important;
  a {
    border-radius: 0.25rem 0px 0px 0.25rem !important;
    ${(props) => props.disabled ? `
      background: #a482d9 !important;
      cursor: default !important;
      &:focus {
        box-shadow: none;
      }
    ` : `background: ${props.theme.colors.nav} !important;`}

    color: #fff !important;
  }

  &.disabled {
    span {
      background: #a482d9 !important;
      cursor: default !important;
      svg {
        color: #fff;
      }
      &:focus {
        box-shadow: none;
      }
    }
    margin-top: 10px !important;
  }
  .sr-only {
    visibility: hidden !important;
    display: none !important;
  }
`;

export const BootstrapPaginationButton = styled(Pagination.First)<BootstrapPaginationArrowsProps>`
  min-width: 82px;
  max-height: 34.02px;
  margin-top: 10px !important;
  margin-right: 10px !important;
  a {
    display: flex;
    max-height: 34.02px;
    border-radius: 0.25rem !important;
    ${(props) => props.disabled ? `
      background: #a482d9 !important;
      cursor: default !important;
      &:focus {
        box-shadow: none;
      }
    ` : `background: ${props.theme.colors.nav} !important;`}

    color: #fff !important;
    span {
      margin: auto !important;
    }
  }
  .sr-only {
    visibility: hidden !important;
    display: none !important;
  }
`;

export const BootstrapPaginationLast = styled(Pagination.Last)<BootstrapPaginationArrowsProps>`
  margin-top: 10px !important;
  margin-left: 10px !important;
  margin-right: 5px !important;
  a {
    border-radius: 0px 0.25rem 0.25rem 0px !important;
    ${(props) => props.disabled ? `
      background: #a482d9 !important;
      cursor: default !important;
      &:focus {
        box-shadow: none;
      }
    ` : `background: ${props.theme.colors.nav} !important;`}

    color: #fff !important;
  }
  &.disabled {
    span {
      background: #a482d9 !important;
      cursor: default !important;
      svg {
        color: #fff;
      }
      &:focus {
        box-shadow: none;
      }
    }
    margin-top: 10px !important;
  }

  .sr-only {
    visibility: hidden !important;
    display: none !important;
  }
`;
