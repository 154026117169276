import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaFormTela } from './validations';

import { UseFormProps } from './protocols/UseFormProps';

type TelaContextData = {
  handleClearForm: () => void;
  formTela: UseFormProps;
  // setIsUpdateCampo: (value: React.SetStateAction<boolean>) => void;
  // isUpdateCampo: boolean;
  // setIsShowModalCampo: (value: React.SetStateAction<boolean>) => void;
  // isShowModalCampo: boolean;
  setIsShowModal: (value: React.SetStateAction<boolean>) => void;
  isShowModal: boolean;
  // generateIdCampo: () => number;
  // formCampo: UseFormProps;
  // setIsUpdate: (value: React.SetStateAction<boolean>) => void;
  // isUpdate: boolean;
};

type FormContextData = {
  register: any;
  handleSubmit: any;
  formState: any;
  setValue: any;
  control: any;
  reset: any;
  getValues: any;
  watch: any;

  //  formLojaFinalizadora
  handleSubmitFinalizadora: any;
  formStateFinalizadora: any;
  FinalizadoraRegister: any;
  FinalizadoraControl: any;
  getValuesFinalizadora: any;
  setValueFinalizadora: any;
};

export const FormContext = createContext({} as FormContextData);
export const TelaContext = createContext({} as TelaContextData);

interface TelaContextProviderProps {
  children: ReactNode;
}

export function TelaContextProvider({
  children,
}: TelaContextProviderProps): JSX.Element {
  // const [isShowModalCampo, setIsShowModalCampo] = useState(false);
  // const [isUpdateCampo, setIsUpdateCampo] = useState(false);
  // const [isUpdate, setIsUpdate] = useState(false);
  const formTelaDefault = {
    cod_modulo: { label: undefined, value: undefined },
  };

  const {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(schemaFormTela),
  });

  const formTela: UseFormProps = {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  };

  useEffect(() => {
    handleClearForm();
  }, []);

  // const [isShowOrderFields, setIsShowOrderFields] = useState(false);
  // const [idCampo, setIdCampo] = useState(1);

  // const generateIdCampo = () => {
  //   setIdCampo((prevstate) => prevstate + 1);
  //   return idCampo;
  // };

  const [isShowModal, setIsShowModal] = useState(false);

  const handleClearForm = (): void => {
    reset(formTelaDefault);
    // clearErrorsFormTela();
  };

  return (
    <TelaContext.Provider
      value={{
        handleClearForm,
        formTela,
        setIsShowModal,
        isShowModal,
        // setIsUpdateCampo,
        // isUpdateCampo,
        // setIsShowModalCampo,
        // isShowModalCampo,
        // setIsShowOrderFields,
        // isShowOrderFields,
        // generateIdCampo,
        // setIsUpdate,
        // isUpdate,
      }}
    >
      {children}
    </TelaContext.Provider>
  );
}

export const useTela = (): TelaContextData => {
  return useContext(TelaContext);
};
