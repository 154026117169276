import styled from 'styled-components';
import Form from '@atlaskit/form';

export const Container = styled.div`
  padding: 10px 10px 0px;

  .inputButton {
    float: right;
    height: 40px;
    line-height: 40px;
    margin-top: -40px;
    align-items: center;
    justify-content: center;
  }

  .datePicker {
    height: 40px;
    margin-top: 30px;
    padding: 7px 0px 0px 7px;
    border: 2px solid #dfe1e6;
    background-color: #fafbfc;
  }
`;

export const InpuTypeDate = styled.input`
  height: 40px;
  margin-top: 30px;
  background-color: ${(props) => (props.disabled ? `#FAFBFC` : `#fafbfc`)};
  border: 2px solid;
  border-color: ${(props) => (props.disabled ? `#FAFBFC` : ` #dfe1e6`)};
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px 20px;
  color: ${(props) => (props.disabled ? `#a5adba` : ` inherit`)};
  cursor: inherit;
  font-size: 14px;
  min-width: 0;
  width: 100%;
  cursor: ${(props) => (props.disabled ? `not-allowed` : `auto`)};
  line-height: 1.4285714285714286;
`;

export const Content = styled(Form)`
  padding: 10px 0px;
`;
