import React from 'react';
import { BootstrapPagination } from './styles';

interface PaginationItemProps {
  page: number;
  isCurrent?: boolean;
  onPageChange: (page: number) => void;
}

const PaginationItem: React.FC<PaginationItemProps> = (props) => {
  const { page, isCurrent = false, onPageChange } = props;

  if (isCurrent) {
    return (
      <BootstrapPagination
        onClick={() => onPageChange(page)}
        activeLabel=""
        active={isCurrent}
      >
        {page}
      </BootstrapPagination>
    );
  }
  return (
    <BootstrapPagination onClick={() => onPageChange(page)}>
      {page}
    </BootstrapPagination>
  );
};

export default PaginationItem;
