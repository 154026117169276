import styled from 'styled-components';

export const AlertBox = styled.div`
  position: relative;
  width: auto;
  transition: 0.5s;
  > * {
    border-radius: 4px;
    position: relative;
    border-bottom: 2px solid #cf1919;
    transition: 0.5s;
  }
  p {
    position: absolute;
    right: 0;
    border: none;
    color: #cf1919;
    transition: 0.5s;
  }
`;

export const DataList = styled.div`
  width: 100%;
  position: absolute;
  top: 118%;
  background-color: #fff;
  border: 1px solid #dfe1e6;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-shadow: 0px 2px 14px 1px rgba(168, 168, 168, 0.19);
  -moz-box-shadow: 0px 2px 14px 1px rgba(168, 168, 168, 0.19);
  box-shadow: 0px 2px 14px 1px rgba(168, 168, 168, 0.19);
  div {
    padding: 6px 10px;
    border-left: 2px solid transparent;
    cursor: default;
    &:hover {
      background-color: #f4f5f7;
      border-left: 2px solid #1938a6;
    }
    &:checked {
      background-color: #f4f5f7;
      border-left: 2px solid #1938a6;
    }
    b {
      font-size: 12px;
      font-weight: 600;
      color: #5c5c5c;
    }
  }
`;
