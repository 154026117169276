import { useEffect, useState } from 'react';
import { Select } from '../protocols';
import api from '~/services/api';
import { errorHandler } from '~/utils/ErrorHandler';
import { Modulo } from '../protocols/Modulo';

export const useModulos = () => {
  const [modulos, setModulos] = useState<Select[]>([]);

  async function getModulos() {
    try {
      const res = await api.get(`/modulo`);
      const { data, success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      const regs = data.map((item: Modulo) => {
        return {
          value: item.cod_modulo,
          label: `${item.des_modulo}`,
        };
      });
      setModulos(regs);
    } catch (e: any) {
      errorHandler(e);
    }
  }

  useEffect(() => {
    getModulos();
  }, []);

  return { modulos };
};
