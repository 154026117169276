import React from 'react';
import { OrderTelasContent } from './OrderTelasContent';
import { TelaContextProvider } from './OrderTelasContext';

interface OrderTelasProps {
  isShow: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrderTelas: React.FC<OrderTelasProps> = ({ isShow, setIsShow }) => {
  return (
    <TelaContextProvider>
      <OrderTelasContent isShow={isShow} setIsShow={setIsShow} />
    </TelaContextProvider>
  );
};

export default OrderTelas;
