import { useEffect, useState } from 'react';
import { Select } from '../protocols';
import api from '~/services/api';
import { errorHandler } from '~/utils/ErrorHandler';
import { useTela } from '../OrderTelasContext';
// import { TipoInformacao } from '../protocols/TipoInformacao';
// import { useTela } from '../TelaContext';

interface OptionTela {
  cod_tela: number;
  des_tela: string;
  num_ordem_menu: number;
}

export const useTelas = () => {
  const [telas, setTelas] = useState<OptionTela[]>([]);

  const { formTela } = useTela();

  const { watch } = formTela;

  const watchCodModulo = watch('cod_modulo');

  useEffect(() => {
    if (watchCodModulo && watchCodModulo.value) {
      const { value } = watchCodModulo;
      getTelas(value);
    }
  }, [watchCodModulo]);

  async function getTelas(codModulo: number) {
    try {
      const res = await api.get(`/tela/modulo/${codModulo}`);
      const { data, success, message } = res.data;

      const regs = data.map((item: any, index: number) => {
        const { cod_tela, des_tela } = item;
        return {
          cod_tela,
          des_tela,
          num_ordem_menu: index,
        };
      });

      setTelas(regs);
    } catch (e: any) {
      errorHandler(e);
    }
  }

  return { telas };
};
